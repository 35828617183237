const $ = document.querySelector.bind(document);
const openSheetButton = $("#open-sheet");
const sheet = $("#sheet");

if (sheet != null) {
  const sheetContents = sheet.querySelector(".contents");
  const draggableArea = sheet.querySelector(".draggable-area");
  let sheetHeight;
  const setSheetHeight = value => {
    sheetHeight = Math.max(0, Math.min(100, value));
    sheetContents.style.height = `${sheetHeight}vh`;
    if (sheetHeight === 100) {
      sheetContents.classList.add("fullscreen");
    } else {
      sheetContents.classList.remove("fullscreen");
    }
  };
  const setIsSheetShown = value => {
    sheet.setAttribute("aria-hidden", String(!value));
    if (value == true) {
      document.body.style.overflow = "hidden";
    }else {
      document.body.style.overflow = "";
    }
  };
  openSheetButton.addEventListener("click", () => {
    let id = openSheetButton.dataset.id;

    sheet.querySelector('img').setAttribute("src", "/media/qrcode/card-" + id + ".jpg")

    setSheetHeight(Math.min(75, 720 / window.innerHeight * 100));
    setIsSheetShown(true);
  });
  sheet.querySelector(".close-sheet").addEventListener("click", () => {
    setIsSheetShown(false);
  });
  sheet.querySelector(".overlay").addEventListener("click", () => {
    setIsSheetShown(false);
  });
  const touchPosition = event => event.touches ? event.touches[0] : event;
  let dragPosition;
  const onDragStart = event => {
    dragPosition = touchPosition(event).pageY;
    sheetContents.classList.add("not-selectable");
    draggableArea.style.cursor = document.body.style.cursor = "grabbing";
  };
  const onDragMove = event => {
    if (dragPosition === undefined) {
      return;
    }
    const y = touchPosition(event).pageY;
    const deltaY = dragPosition - y;
    const deltaHeight = deltaY / window.innerHeight * 100;
    setSheetHeight(sheetHeight + deltaHeight);
    dragPosition = y;
  };
  const onDragEnd = () => {
    dragPosition = undefined;
    sheetContents.classList.remove("not-selectable");
    draggableArea.style.cursor = document.body.style.cursor = "";
    if (sheetHeight < 70) {
      setIsSheetShown(false);
    } else {
      setSheetHeight(75);
    }
  };
  draggableArea.addEventListener("mousedown", onDragStart);
  draggableArea.addEventListener("touchstart", onDragStart);
  window.addEventListener("mousemove", onDragMove);
  window.addEventListener("touchmove", onDragMove);
  window.addEventListener("mouseup", onDragEnd);
  window.addEventListener("touchend", onDragEnd);
}
